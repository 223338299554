import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueRouter from 'vue-router'
import common from './common/common.js'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import axios from "axios"
import qs from 'qs'

var instance = axios.create({
    baseURL: 'https://www.purefunds.cn/api',//'http://42.192.76.149:9402', //'http://47.97.116.171:9402',
    timeout: 10000,
    withCredentials: true,
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
});

Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.prototype.common = common
Vue.prototype.$request = instance
Vue.prototype.qs = qs
Vue.config.productionTip = false

import P404 from "./components/404"
import Home from './components/Home'
import EnHome from "./components/EnHome";
import TeamMemberDetail from "./components/TeamMemberDetail"
import Team from "./components/Team"
import News from "./components/News";
import NewsDetail from "./components/NewsDetail";
import Enterprise from "./components/Enterprise";
import Jobs from "./components/Jobs";
import JobDetail from "./components/JobDetail";
import AboutUs from "./components/AboutUs";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

let router = new VueRouter({
    // mode: 'history',
    routes: [
        {
            path: common.PAGE_404,
            name: '404',
            component: P404,
        },
        {
            path: common.PAGE_EN_404,
            name: 'EN-404',
            component: P404,
        },

        {
            path: common.PAGE_HOME,
            name: 'Home',
            component: Home,
        },
        {
            path: common.PAGE_EN_HOME,
            name: 'EN-Home',
            component: EnHome,
        },

        {
            path: common.PAGE_TEAM,
            name: 'Team',
            component: Team,
            meta: {keepAlive: false}
        },
        {
            path: common.PAGE_EN_TEAM,
            name: 'EN-Team',
            component: Team,
            meta: {keepAlive: false}
        },

        {
            path: common.PAGE_TEAM_MEMBER,
            name: 'TeamMemberDetail',
            component: TeamMemberDetail,
        },
        {
            path: common.PAGE_EN_TEAM_MEMBER,
            name: 'EN-TeamMemberDetail',
            component: TeamMemberDetail,
        },

        {
            path: common.PAGE_NEWS,
            name: 'News',
            component: News,
            meta: {keepAlive: false}
        },
        {
            path: common.PAGE_EN_NEWS,
            name: 'EN-News',
            component: News,
            meta: {keepAlive: false}
        },

        {
            path: common.PAGE_NEWS_DETAIL,
            name: 'NewsDetail',
            component: NewsDetail,
        },
        {
            path: common.PAGE_EN_NEWS_DETAIL,
            name: 'EN-NewsDetail',
            component: NewsDetail,
        },

        {
            path: common.PAGE_ENTERPRISE,
            name: 'Enterprise',
            component: Enterprise,
        },
        {
            path: common.PAGE_EN_ENTERPRISE,
            name: 'EN-Enterprise',
            component: Enterprise,
        },

        {
            path: common.PAGE_JOB,
            name: 'Jobs',
            component: Jobs
        },
        {
            path: common.PAGE_EN_JOB,
            name: 'EN-Jobs',
            component: Jobs
        },

        {
            path: common.PAGE_JOB_DETAIL,
            name: 'JobDetail',
            component: JobDetail
        },
        {
            path: common.PAGE_EN_JOB_DETAIL,
            name: 'EN-JobDetail',
            component: JobDetail
        },

        {
            path: common.PAGE_ABOUT_US,
            name: 'AboutUs',
            component: AboutUs
        },
        {
            path: common.PAGE_EN_ABOUT_US,
            name: 'EN-AboutUs',
            component: AboutUs
        }
    ]
});

new Vue({
    el: '#app',
    router,
    render: h => h(App),
    components: {App},
    template: '<App/>'
}).$mount('#app')
