<template>
    <div class="modal-backdrop fade show" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="close">
                    <div class="img-close" @click="closeModal"></div>
                </div>

                <ul class="menu-nav" :index="defaultIndex(index)">
                    <li :class="topIndex === 0 ? 'active' : ''" @click="topIndexChose(0)">{{tabNames[0]}}</li>
                    <li :class="topIndex === 1 ? 'active' : ''" @click="topIndexChose(1)">{{tabNames[1]}}</li>
                    <li :class="topIndex === 2 ? 'active' : ''" @click="topIndexChose(2)">{{tabNames[2]}}</li>
                    <li :class="topIndex === 3 ? 'active' : ''" @click="topIndexChose(3)">{{tabNames[3]}}</li>
                    <li :class="topIndex === 6 ? 'active' : ''" @click="topIndexChose(6)">{{tabNames[6]}}</li>
                    <li :class="topIndex === 4 ? 'active' : ''" @click="topIndexChose(4)">{{tabNames[4]}}</li>
                    <li :class="topIndex === 5 ? 'active' : ''" @click="topIndexChose(5)">{{tabNames[5]}}</li>
                    <li style="background: none !important;" @click="changeLanguage">{{isEn ? '中文' : 'EN'}}</li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "InfoModel",
        props: {
            index: 0
        },
        data() {
            return {
                isEn: false,
                topIndex: 0,
                tabNamesZh: [
                    '首页',
                    '赤子团队',
                    '发展动态',
                    '被投企业',
                    '工作机会',
                    '联系我们',
                    '赤子创服'
                ],
                tabNamesEn: [
                    'Home',
                    'People',
                    'Build',
                    'Companies',
                    'Jobs',
                    'Contact',
                    'PureValue'
                ],
                tabNames: []
            }
        },
        mounted() {
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
            this.setNames()
        },
        methods: {
            setNames() {
                if (this.isEn) {
                    this.tabNames = this.tabNamesEn
                } else {
                    this.tabNames = this.tabNamesZh
                }
            },
            changeLanguage() {
                if (this.isEn) {
                    this.$router.push({
                        path: this.common.PAGE_HOME
                    })
                } else {
                    this.$router.push({
                        path: this.common.PAGE_EN_HOME
                    })
                }
                this.isEn = !this.isEn
                this.setNames()
                this.closeModal()
            },
            closeModal() {
                this.$emit("onclose", true)
            },
            defaultIndex(index) {
                this.topIndex = index;
            },
            topIndexChose(index) {
                this.topIndex = index;
                this.$emit("onIndex", index)
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "src/common/style/variable.scss";

    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
        pointer-events: auto;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-backdrop.show {
        opacity: 1;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        display: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: 0;
    }

    .modal-dialog {
        position: fixed;
        margin: auto;
        max-width: 100%;
        width: 100%;
        height: 100%;
        right: 0;
        transform: translate3d(0%, 0, 0);
    }

    .modal.fade.show .modal-dialog {
        right: -50%;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }


    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 0px solid rgba(0, 0, 0, 0);
        border-radius: 0;
        outline: 0;
        overflow-y: auto;
    }

    .close {
        width: 3rem;
        height: 3rem;
        margin: 4rem auto 0;
    }

    .img-close {
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-image: url("../assets/home_close.png");
    }

    .img-close:active,
    .img-close:hover {
        background-image: url("../assets/home_close_on.png");
    }

    .menu-nav {
        margin: 2rem auto 0;
        list-style-type: none;
        padding: 0;
    }

    .menu-nav li {
        background: none;
        margin-bottom: 1rem;
        color: $color-text-title;
        text-align: center;
        padding: 0.5rem 1rem;
        font-size: 1.3em;
        display: list-item;
        cursor: pointer;

        &:hover {
            color: $color-red;
        }

        &.active {
            background-image: url("../assets/home_nav_bg.png");
            -webkit-background-size: 100% 100%;
            -moz-background-size: 100% 100%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }

</style>