<template>
    <div id="app" style="height: 100vh">
        <keep-alive>
            <router-view style="height: 100%" v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view style="height: 100%" v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>
    export default {
        name: 'App',
    }
</script>

<style>
    @import "common/style/variable.scss";
    @font-face {
        font-family: '隶书';
        src: url("./common/style/隶书.ttf");
    }

    #app {
        /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
        font-family: "Gotham SSm A","Gotham SSm B",Helvetica,Arial,"Microsoft Yahei",SimSun;
        /*-webkit-font-smoothing: antialiased;*/
        /*-moz-osx-font-smoothing: grayscale;*/
        /*text-align: center;*/
        /*color: #2c3e50;*/
    }
</style>
