<template>
    <div class="re-item">
        <div style="width: 100%" class="pc-content">
            浙江赤子股权投资基金管理有限公司 版权所有 | <span @click="goBeian" style="cursor:pointer">浙ICP备16040420号-1</span> |
            <img src="../assets/icp_police.png" style="width: 14px; height: 14px; object-fit: cover">
            浙公网安备33011802002082号
        </div>

        <div style="width: 100%" class="mobile-content">
            浙江赤子股权投资基金管理有限公司 版权所有<br/> <a href="https://beian.miit.gov.cn">浙ICP备16040420号-1</a>
            <img src="../assets/icp_police.png" style="width: 14px; height: 14px; object-fit: cover">
            浙公网安备33011802002082号
        </div>

    </div>
</template>

<script>
    export default {
        name: "Registered",
        methods:{
            goBeian(){
                window.open('https://beian.miit.gov.cn')
            }
        }
    }
</script>

<style scoped>
    a{
        color:#fff;
    }
    .re-item {
        font-size: 12px;
        color: #4F5251;
        text-align: center;
        width: 100%;
        padding: 0 1rem;
        margin: 0;
        line-height: 1.2em;
    }
    .mobile-content {
        display: block;
        line-height: 1.5em;
    }

    .pc-content {
        display: none;
    }

    @media only screen and (min-width: 768px) {
        .mobile-content {
            display: none;
        }

        .pc-content {
            display: block;
        }
    }

</style>