<template>
    <div>
        <tab :index="4" ref="tab_top"></tab>
        <div style="width: 100%;overflow: hidden; height: 100%">
            <img ref="top_img" src="../assets/main_job_bg.png" style="height: 100%; width: 100%; object-fit: cover; overflow-y: hidden">
            <div class="tab-item" ref="main" @scroll="handleScroll">
                <div class="top-title-bg" ref="content" @scroll="handleScroll">
                    <div class="job-content-box">
                        <div class="top-span"></div>
                        <div class="top-title " ref="title">{{joinN}}</div>
                        <div class="top-describe"  v-html="describe"></div>

                        <div class="job-content" :class="showAnim ? 'show' : ''">
                            <div class="job-item" v-if="investedCount !== ''">
                                <div  class="job-type">{{investedN}}</div>
                                <p v-if="isEn" class="action-bt-job" @click="toJobDetail(investedCount, 1)">
                                    {{investedCount}} position</p>
                                <p v-else class="action-bt-job" @click="toJobDetail(investedCount, 1)">
                                    共{{investedCount}}个职位</p>
                            </div>

                            <div class="job-item" v-if="ourCount !== ''">
                                <div class="job-type">{{fundN}}</div>
                                <p v-if="isEn"  class="action-bt-job" @click="toJobDetail(ourCount, 2)">{{ourCount}}
                                    position</p>
                                <p v-else class="action-bt-job" @click="toJobDetail(ourCount, 2)">共{{ourCount}}个职位</p>
                            </div>
                        </div>
                    </div>

                    <div style="flex: 1; -webkit-flex: 1"></div>

                    <registered style="padding: 10px"></registered>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Tab from "../common/Tab"
    import Registered from "../common/Registered";

    export default {
        name: "Jobs",
        components: {
            Tab,
            Registered
        },
        data() {
            return {
                showAnim: false,
                barH: 0,
                isEn: false,
                joinN: 'Join us',
                investedN: 'Invested',
                fundN: 'Pure',

                describe: '',
                investedCount: '',
                ourCount: ''
            }
        },
        created() {
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
            this.setWord()
            this.getJobsCount()
            this.getTopTitle()
        },
        mounted() {
            this.barH = this.$refs.tab_top.$refs.bar.offsetHeight
        },
        methods: {
            handleScroll() {
                let scrollTop = this.$refs.content.scrollTop
                if (scrollTop === 0) {
                    scrollTop = this.$refs.main.scrollTop
                }
                // let bgh = this.$refs.title.offsetHeight
                let al = Number((scrollTop / 90).toFixed(1))
                this.$refs.tab_top.$refs.bar.style.background = 'rgba(255, 255, 255, ' + al + ')'
            },
            setWord() {
                this.showAnim = false
                if (this.isEn) {
                    this.joinN = 'Join us'
                    this.investedN = 'Companies'
                    this.fundN = 'Pure Fund'
                } else {
                    this.joinN = '加入我们'
                    this.investedN = '赤子基金被投企业'
                    this.fundN = '赤子基金'
                }
                let that = this
                setTimeout(function () {
                    that.showAnim = true
                }, 500)
            },
            toJobDetail(count, type) {
                if (this.isEn) {
                    this.$router.push({
                        path: this.common.PAGE_EN_JOB_DETAIL,
                        query: {type: type}
                    })
                } else {
                    this.$router.push({
                        path: this.common.PAGE_JOB_DETAIL,
                        query: {type: type}
                    })
                }

            },
            getJobsCount() {
                this.$request.post('/children/work/total',
                    this.qs.stringify({
                        language: this.isEn ? 2 : 1,
                        type: 1
                    }))
                    .then(res => {
                        this.investedCount = res.data.data
                    })

                this.$request.post('/children/work/total',
                    this.qs.stringify({
                        language: this.isEn ? 2 : 1,
                        type: 2
                    }))
                    .then(res => {
                        this.ourCount = res.data.data
                    })
            },
            getTopTitle() {
                this.$request.post('/children/article/getById',
                    this.qs.stringify({
                        type: 4,
                        language: this.isEn ? 2 : 1
                    }))
                    .then(res => {
                        const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
                        if(width<768){
                            this.describe = res.data.data.suggest
                        }else{
                            this.describe =res.data.data.content
                        }
                    })
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "src/common/style/variable";

    .tab-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        margin-top: 0;
    }

    .top-title-bg {
        position: relative;
        text-align: center;
        padding: 0rem 2rem 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        -webkit-flex-direction: column;
        // justify-content: center;
        align-items: center;
    }


    .top-title {
        font-size: 2em;
        font-weight: bold;
        cursor: pointer;
        color: $color-red;
        opacity: 1;
        transform: translateY(0);
        margin-top: 6rem;
    }

    .top-describe {
        width: 100%;
        opacity: 1;
        margin: 1rem 0;
        font-size: 14px;
        color: #333333;
        line-height: 2em;
        text-align: left;
    }

    .job-content {
        width: 100%;
        position: relative;
        transform: translateY(10px);
        opacity: 0;
        transition: all 0s ease 0s;
        &.show {
            transform: translateY(0);
            opacity: 1;
            transition: all .5s ease 0s;
        }
    }

    .job-item {
        background-color: #FFFFFF;
        padding: 2rem;
        text-align: center;
        margin: 1rem 0 2rem;
    }
    .job-type {
        font-size: 1em; color: #333333;width: auto;
    }
    .action-bt-job {
        font-size: 0.8em;
        margin: 1.5rem auto 0;
        width: 7.6em;
        color: white;
        padding: 0.4em 0;
        background-color: $color-red;
        display: block;
        cursor: pointer;

        &:active,
        &:hover {
            opacity: 0.8;
        }
    }

    @media only screen and (min-width: 768px) {
        .top-title-bg {
            padding: 0 2rem 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .job-content-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width:100%;
                height:100%;
            }
        }
        .top-title {
            font-size: 3.8rem;
            margin-top: 5rem;
        }
        .job-content {
            width: 780px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
        .job-item {
            width: 360px;
            height: 210px;
            background-color: #FFFFFF;
            // padding: 2rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // margin: 1rem 0 ;
        }

        .top-describe {
            max-width: 644px;
            margin: 1.5rem auto;
            font-size: 1em;
            line-height: 1.8em;
            text-align: center;
        }
        .job-type {
            font-size: 30px;
        }
        .action-bt-job {
            font-size: 20px;
        }
    }

    @media only screen and (min-width: 1080px) {
        .top-title {
            font-size: 3.8em;
        }
        .top-describe {
            font-size: 1em;
            line-height: 1.8em;
        }
    }

    @media only screen and (min-width: 1440px) {
        .top-title {
            font-size: 4.7em;
        }
        .top-describe {
            font-size: 1.1em;
            line-height: 1.8em;
        }
    }

    @media only screen and (min-width: 1920px) {
        .top-title {
            font-size: 5.6em;
        }
        .top-describe {
            font-size: 1.2em;
            line-height: 2em;
        }
    }
</style>