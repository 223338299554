<template>
    <div>
        <tab :index="3" ref="tab_top"></tab>
        <div style="width: 100%;overflow: hidden;">
            <div class="tab-item" ref="content" @scroll="handleScroll">
                <div style="position: relative; height: 100%">
                    <div ref="bg_img" class="top-title-item"
                         :style="'background-image: url('+ bgImg +')'">
                        <div class="top-title">{{titleN}}</div>
                        <div class="top-describe" v-html="describe"></div>
                    </div>

                    <div style="width: auto; margin: 4rem 2rem 1rem; display: flex; flex-direction: row;">
                        <p class="type-tips">{{optionN}}</p>
                        <p v-if="typeName" class="type-name" @click="showModal()">{{typeName}}</p>
                        <p v-else class="type-name" @click="showModal()">{{tradeN}}</p>
                        <img v-if="typeName" src="../assets/home_close.png"
                             style="width: 1em; height: 1em; object-fit: cover; margin: 0.2em"
                             @click="resetAll">
                    </div>

                    <div style="width: auto; height: 1px; background-color: #CBC8C7; margin: 0 2rem"></div>

                    <div style="min-height: 6vh">
                        <div :class="showAnim ? 'ep-list show' : 'ep-list'">

                            <div class="ep-list-item" v-if="epList" v-for="(item, index) in epList" :key="index"
                                 @click="toDetail(item)">
                                <div class="ep-list-name">
                                    {{item.name}}
                                </div>
                                <div v-if="typeName" class="ep-list-describe" v-html="item.introduce"></div>
                                <p class="item-line"></p>
                            </div>
                        </div>
                    </div>

                    <div style="margin: 4rem auto 2rem; width: 3rem; height: auto">
                        <img src="../assets/logo.png" style="width: 100%; margin-bottom: 2rem">
                    </div>

                    <registered style="padding: 10px"></registered>

                </div>

            </div>
        </div>


        <div v-if="showType" class="modal-backdrop fade show" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <div class="close" @click="isChose ? submit() : closeModal()">
                        <div class="img-close submit" v-if="isChose"></div>
                        <div class="img-close" v-else></div>
                        <div class="action-submit">{{isChose ? actionSubmitN :
                            actionCloseN}}
                        </div>
                    </div>

                    <p style="font-size: 10px; color: #999999; width: auto; margin: 10px auto; cursor: pointer"
                       @click="resetDialogInfo">{{isChose ? resetN : ''}}
                    </p>

                    <div style="width: 100%; display: flex; flex-direction: row; margin: 2rem auto 0;">
                        <div class="trade-name">
                            {{tradeN}}
                        </div>
                        <div style="width: 50%; padding-left: 2rem">
                            <ul class="list">
                                <li v-for="(item, index) in infoData" :key="index">
                                <span :class="item.chose ? 'active' : ''"
                                      @click="choseType(item)">{{ item.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <info-modal v-on:onclose="infoShowModal" :show="infoShow" :info="epInfo"></info-modal>

    </div>
</template>

<script>
    import Tab from "../common/Tab"
    import InfoModal from "../common/InfoModal";
    import Registered from "../common/Registered";

    export default {
        name: "Enterprise",
        components: {
            Tab,
            InfoModal,
            Registered
        },
        data() {
            return {
                showAnim: false,
                barH: 0,
                isEn: false,
                tradeN: '',
                optionN: '',
                titleN: '',
                resetN: '',
                actionSubmitN: '',
                actionCloseN: '',
                allN: '',

                typeName: '',
                showType: false,
                infoShow: false,
                epInfo: '',
                describe: '',
                epList: '',
                isChose: false,
                infoData: [],
                choseData: [],
                bgImg:require("@/assets/main_enterprise_bg.png")
            }
        },
        created() {
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
            this.setWord()
            this.getAllType()
            this.getEpList()
            this.getTopTitle()
        },
        mounted() {
            this.barH = this.$refs.tab_top.$refs.bar.offsetHeight
        },
        methods: {
            handleScroll() {
                let scrollTop = this.$refs.content.scrollTop
                let bgh = this.$refs.bg_img.offsetHeight
                let al = Number((scrollTop / (bgh * 0.6 - this.barH)).toFixed(1))
                this.$refs.tab_top.$refs.bar.style.background = 'rgba(255, 255, 255, ' + al + ')'
            },
            setWord() {
                if (this.isEn) {
                    this.titleN = 'You, Changing the world'
                    this.optionN = 'Option'
                    this.tradeN = 'Trade'
                    this.resetN = 'Reset'
                    this.allN = 'All'
                    this.actionCloseN = 'Close'
                    this.actionSubmitN = 'Submit'
                } else {
                    this.titleN = '你们，改变着世界'
                    this.optionN = '选择'
                    this.tradeN = '行业'
                    this.resetN = '重置'
                    this.allN = '全部'
                    this.actionCloseN = '关闭'
                    this.actionSubmitN = '提交'
                }
            },
            showModal() {
                this.showType = true;
                for (let i in this.choseData) {
                    if (this.choseData[i].chose) {
                        this.infoData[i].chose = true
                    } else {
                        this.infoData[i].chose = false
                    }
                }
                this.updateListState()
            },
            closeModal() {
                this.showType = false;
            },
            resetDialogInfo() {
                for (let index in this.infoData) {
                    this.infoData[index].chose = false
                }
                this.isChose = false
            },
            resetAll() {
                this.typeName = ''
                this.choseData = []
                this.getEpList()
            },
            setTypeName() {
                let name = ''
                let ids = ''
                for (let index in this.choseData) {
                    if (this.choseData[index].chose && this.choseData[index].id !== -1) {
                        name += this.choseData[index].name + ","
                        ids += this.choseData[index].id + ","
                    }
                }
                if (name.length > 0) {
                    this.typeName = name.substring(0, name.length - 1)
                }
                if (ids.length > 0) {
                    ids = ids.substring(0, name.length - 1)
                }
                this.getEpList(ids)
            },
            choseType(item) {
                item.chose = !item.chose
                let isAll = true;
                let chose = false;
                for (let index in this.infoData) {
                    if (item.id === -1) {
                        this.infoData[index].chose = item.chose
                    }
                    if (index > 0) {
                        if (this.infoData[index].chose) {
                            chose = true
                        } else {
                            isAll = false
                        }
                    }
                }
                this.infoData[0].chose = isAll
                this.isChose = chose
                this.$forceUpdate()
            },
            updateListState() {
                let isAll = true;
                let chose = false;
                for (let index in this.infoData) {
                    if (index > 0) {
                        if (this.infoData[index].chose) {
                            chose = true
                        } else {
                            isAll = false
                        }
                    }
                }
                this.infoData[0].chose = isAll
                this.isChose = chose
            },
            submit() {
                this.choseData = []
                this.choseData = JSON.parse(JSON.stringify(this.infoData))
                this.closeModal()
                this.setTypeName()
            },
            toDetail(item) {
                this.epInfo = item
                this.infoShow = true
            },
            infoShowModal() {
                this.infoShow = false
            },

            getAllType() {
                this.$request.post('/children/trade/listAll',
                    this.qs.stringify({
                        language: this.isEn ? 2 : 1
                    }))
                    .then(res => {
                        this.infoData = []
                        this.infoData.push({name: this.allN, chose: false, id: -1})
                        this.infoData = this.infoData.concat(res.data.data)
                    })
            },
            getEpList(ids) {
                this.showAnim = false
                this.$request.post('/children/company/listPage',
                    this.qs.stringify({
                        page: 0,
                        rows: 100,
                        language: this.isEn ? 2 : 1,
                        tradeIds: ids ? ids : ''
                    }))
                    .then(res => {
                        this.epList = res.data.data
                        let that = this
                        setTimeout(function () {
                            that.showAnim = true
                        }, 500)
                    })
            },
            getTopTitle() {
                this.$request.post('/children/article/getById',
                    this.qs.stringify({
                        type: 3,
                        language: this.isEn ? 2 : 1
                    }))
                    .then(res => {
                        // this.describe = res.data.data.content
                        const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
                        if(width<768){
                            this.describe = res.data.data.suggest
                        }else{
                            this.describe =res.data.data.content
                        }
                    })
            },
        }

    }
</script>

<style lang="scss">
    @import "src/common/style/variable";

    .tab-item {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        margin-top: 0;
    }

    .top-title-item {
        padding: 0 2rem;
        min-height: 21rem;
        text-align: center;
        width: 100%;
        display: block;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
    }

    .top-title {
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        color: $color-red;
        margin-top: 10rem;
        transform: translateY(0);
        opacity: 1;

    }

    .top-describe {
        font-size: 14px;
        color: black;
        margin-top: 1.8rem;
        margin-bottom: 3rem;
        line-height: 25px;
        font-weight: 500;
        text-align: left;
        transform: translateY(0);
        opacity: 1;
    }

    .type-tips {
        color: #666666;
        font-size: 0.9em
    }

    .type-name {
        color: $color-red;
        margin-left: 1rem;
        cursor: pointer;
        font-size: 0.9em;
    }

    .action-submit {
        font-size: 14px;
        margin-top: 5px;
        font-weight: 500;
    }

    .trade-name {
        width: 50%;
        font-size: 1em;
        color: black;
        font-weight: 600;
        text-align: right;
        padding-right: 2rem
    }

    .ep-list {
        width: 100%;
        padding: 0 2rem;

        transform: translateY(50px);
        opacity: 0;
        transition: all 0s ease 0s;

        &.show {
            transform: translateY(0);
            opacity: 1;
            transition: all .5s ease 0s;
        }
    }

    .ep-list-item {
        cursor: pointer;

        &:hover div {
            color: $color-red !important;
        }
    }

    .ep-list-name {
        color: $color-text-title;
        font-size: 1em;
        text-align: left;
        width: auto;
        padding: 1rem 0 0;
    }

    .ep-list-describe {
        color: #8a8c8c;
        // font-size: 0.7em;
        text-align: left;
        width: auto;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        font-family: "Gotham SSm A","Gotham SSm B",Helvetica,Arial,"Microsoft Yahei",SimSun;
        font-weight: 200;
        font-size: 14px;
        line-height: 28px;
        /*-webkit-line-clamp: 2;*/
        /*max-height: 3em;*/
    }

    .item-line {
        width: 100%;
        height: 1px;
        background-color: #CBC8C7;
        margin-top: 1rem;
        overflow: hidden;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;

    }

    @media only screen and (min-width: 768px) {
        .top-title-item {
            justify-content: center;
            min-height: 33vw;
        }
        .top-title {
            font-size: 3.2em;
            font-weight: bold;
            cursor: pointer;
            color: $color-red;
            margin-top: 12vw;
        }
        .top-describe {
            font-size: 1em;
            color: $color-text-title;
            margin-top: 2.2rem;
            line-height: 25px;
            font-weight: 500;
            max-width: 520px;
            text-align: center;
        }
        .type-tips {
            font-size: 1.1em
        }
        .type-name {
            font-size: 1.1em;
        }
        .action-submit {
            font-size: 16px;
        }
        .trade-name {
            font-size: 20px;
        }
        .ep-list-describe {
            // font-size: 0.9em;
            /*max-height: 2.8em;*/
        }
        .ep-list {
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
        }
        .ep-list-item {
            width: 25%;
            padding-right: 1rem;
        }
        .top-describe {
            margin-left: 50%;
            text-align: left;
        }
    }

    @media only screen and (min-width: 1080px) {
        .top-title {
            font-size: 4rem;
        }
         .top-describe{
            font-size: 1.25em;
            line-height:32px; 
         }
       .ep-list-name{
           font-size:1.25em;
       } 
    }

    @media only screen and (min-width: 1920px) {

        .top-title {
            font-size: 5rem;
        }
        .top-describe {
            font-size: 20px;
            line-height: 34px;
        }
    }


    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1040;
        width: 100vw;
        height: 100vh;
        pointer-events: auto;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-backdrop.show {
        opacity: 1;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        display: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: 0;
    }

    .modal-dialog {
        position: fixed;
        margin: auto;
        max-width: 100%;
        width: 100%;
        height: 100%;
        right: 0;
        transform: translate3d(0%, 0, 0);
    }

    .modal.fade.show .modal-dialog {
        right: -50%;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }


    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        pointer-events: auto;
        text-align: center;
        background-color: #fff;
        background-clip: padding-box;
        border: 0px solid rgba(0, 0, 0, 0);
        border-radius: 0;
        outline: 0;
        overflow-y: auto;
    }

    .close {
        width: 3rem;
        height: auto;
        margin: 4rem auto 0;
        cursor: pointer;

        &:hover {
            color: $color-red;
        }
    }

    .img-close {
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-image: url("../assets/home_close.png");

        &.submit {
            background-image: url("../assets/home_sure.png");
        }
    }

    .img-close:active,
    .img-close:hover {
        background-image: url("../assets/home_close_on.png");

        &.submit {
            background-image: url("../assets/home_sure.png");
        }
    }

    .list {
        text-align: left;
        list-style-type: none;
        box-sizing: border-box;
        padding: 0;
        margin: 0
    }

    .list li {
        background: none;
        display: list-item;
        padding: 0;
        margin: 0 0 1.6rem;

    }

    .list li span {
        color: $color-black;
        width: auto;
        font-size: 1em;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            color: $color-red;
        }
    }

    .list li .active {
        width: auto;
        font-weight: 600;
        cursor: pointer;
        color: $color-red;
        border-bottom: 1px solid $color-red;

    }

    @media only screen and (min-width: 768px) {
        .list li span {
            font-size: 20px;
        }
    }

    @media only screen and (min-width: 1080px) {
        .close {
            width: 50px;
            height: auto;

        }

        .img-close {
            width: 50px;
            height: 50px
        }
    }

    @media only screen and (min-width: 1440px) {
        .close {
            width: 60px;
            height: auto;

        }

        .img-close {
            width: 60px;
            height: 60px
        }
    }

    @media only screen and (min-width: 1920px) {
        .close {
            width: 70px;
            height: auto;

        }

        .img-close {
            width: 70px;
            height: 70px
        }
    }

</style>