export default {
    PAGE_404: '/404/',
    PAGE_HOME: '/',
    PAGE_TEAM: '/team/',
    PAGE_TEAM_MEMBER: '/team/member-detail/',
    PAGE_NEWS: '/news/',
    PAGE_NEWS_DETAIL: '/news/detail/',
    PAGE_ENTERPRISE: '/enterprise/',
    PAGE_JOB: '/job',
    PAGE_JOB_DETAIL: '/job/detail/',
    PAGE_ABOUT_US: '/about/',

    PAGE_EN_404: '/en/404/',
    PAGE_EN_HOME: '/en/',
    PAGE_EN_TEAM: '/en/team/',
    PAGE_EN_TEAM_MEMBER: '/en/team/member-detail/',
    PAGE_EN_NEWS: '/en/news/',
    PAGE_EN_NEWS_DETAIL: '/en/news/detail/',
    PAGE_EN_ENTERPRISE: '/en/enterprise/',
    PAGE_EN_JOB: '/en/job/',
    PAGE_EN_JOB_DETAIL: '/en/job/detail/',
    PAGE_EN_ABOUT_US: '/en/about/',

    LANGUAGE_EN: 'EN',
    LANGUAGE_ZH: 'ZH',

    languageType: function (context) {
        if (context.$route.name.startsWith('EN-')) {
            return this.LANGUAGE_EN
        }
        return this.LANGUAGE_ZH
    }
}
