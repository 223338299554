<template>
    <div>
        <tab :index="2"></tab>
        <div style="width: 100%;overflow: hidden;">
            <div class="tab-item" style="width: 100%; text-align: center">

                <div class="content">
                    <div class="title">{{detail.title}}</div>
                    
                        <div class="describe" v-html="content"></div>
                    
                    
                    <div style="margin: 0 auto; width: 3rem; height: auto">
                        <img src="../assets/logo.png" style="width: 100%; margin-bottom: 2rem">
                    </div>

                    <registered style="padding: 10px"></registered>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Tab from "../common/Tab"
    import Registered from "../common/Registered";

    export default {
        name: "NewsDetail",
        components: {
            Tab,
            Registered
        },
        data() {
            return {
                isEn: false,
                detail: '',
                content:''
            }
        },
        created() {
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
            let id = this.$route.query.id
            let type = this.$route.query.type
            this.getDetail(id, type)
        },
        methods: {
            getDetail(id, type) {
                this.$request.post('/children/news/getById',
                    this.qs.stringify({
                        id: id
                    }))
                    .then(res => {
                        this.detail = res.data.data
                        let richtext= res.data.data.content;
                        const regex = new RegExp('<img', 'gi');
                        richtext= richtext.replace(regex, `<img style="display:block;margin:0 auto;max-width: 100%;"`);
                        // richtext = richtext.replace(/\r\n/g,"<br>")
                        // richtext = richtext.replace(/\n/g,"<br>")                        
                        this.content = richtext;
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../common/style/variable";

    .tab-item {
        position: absolute;
        width: 100%;
        height: calc(100% - 4rem);
        overflow-y: auto;
        margin-top: 4rem;
        display: list-item;
    }

    .content {
        width: 100%;
    }

    .title {
        margin: 4rem 2rem 0;
        font-size: 2em;
        color: $color-black;
        font-weight: bold;
    }

    .describe {
        margin: 4rem 2rem;
        font-size: 1em;
        color: $color-text-title;
        text-align: left;
        min-height: 60vh;
        font-weight:200;
        font-family: "Gotham A","Gotham B",Helvetica,Arial,"Microsoft Yahei",SimSun;
    }
    @media only screen and (min-width: 768px) {
        .content {
            width: 48em;
            max-width: 48em;
            margin: 0 auto;
        }
        .tab-item {
            height: calc(100% - 5.8rem);
            margin-top: 5.8rem;
        }
    }
     @media only screen and (min-width: 1080px) {
        .content {
            width: 80%;
            max-width:60em;
            margin: 0 auto;
        }
        .title {
            font-size: 3.4em;
        }
        .describe{
           font-size: 1.25em; 
        }
    }

</style>