<template>
    <div style="width: 100%;">
        <div style="height: 100%; width: 100%; align-items: center; justify-content: center; vertical-align: center;text-align: center">
            <p style="color: #999999; font-size: 2em; ">你所访问的页面不存在！</p>
            <img src="../assets/logo.png">
        </div>

    </div>
</template>

<script>
    export default {
        name: "404",
    }
</script>

<style>

</style>