<template>
    <div>
        <tab :index="0" style="background-color: #FFFFFF;box-shadow: none;"></tab>
        <div class="content">
            <div class="tab-item">

                <div v-if="epInfoList" class="main">
                    <div class="swiper-container" id="info-swiper" style="width: 100%; position: relative;">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <img class="home-main-img">
                                <div class="home-main-content">
                                    <!-- <div class="top-img-bg"> 
                                        <img src="../assets/logo.png" style="width: 100%; height: 100%; object-fit: cover">
                                    </div> -->
                                    <div class="min-title-img-box">
                                        <img src="../assets/small_new_logo.png" alt="1" class="small_new_logo">
                                        <img src="../assets/small_new_logo_img.png" alt="1" class="small_new_logo_text">
                                    </div>
                                    <img class="title-img" src="../assets/logo_new.png" />
                                    <p v-if="isEn" class="click-tips">
                                        If you want to know some invested enterprises, please click the
                                        drop-down button
                                    </p>
                                    <p v-else class="click-tips">
                                        如欲了解部分被投企业，请点击下拉按钮
                                    </p>

                                    <div class="next-icon">
                                        <img src="../assets/home_arrow_down.png"
                                             style="width: 100%; height: auto; cursor: pointer; object-fit: cover"
                                             @click="next">
                                    </div>

                                </div>
                            </div>

                            <div class="swiper-slide" style="background-color: #FFFFFF">

                                <div class="swiper-container home-info-right" id="img-swiper">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide" v-for="(item, index) in epInfoList" :key="index">
                                            <img v-if="isSmall" :src="item.smallPic" class="home-info-img" style="object-fit: fill"/>
                                            <img v-else :src="item.pic" class="home-info-img" style="object-fit: fill"/>
                                        </div>
                                    </div>
                                </div>

                                <div class="swiper-container home-info-left" id="content-swiper">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide" v-for="(item, index) in epInfoListLeft" :key="index"
                                             v-if="item.isShow === 1">
                                            <div :class="(index % 2 === 0 ? 'home-info-content' : 'home-info-content red')">
                                                <div style="margin: 2rem auto 2rem; position: relative;">
                                                    <svg width="80" height="80" class="timer-svg">
                                                        <g>

                                                            <circle r="37.2" cy="40" cx="40"
                                                                    stroke-width="2.6"
                                                                    stroke="#FFFFFF" fill="none"/>
                                                            <circle class="progress-circle" r="37" cy="40" cx="40"
                                                                    stroke-width="3"
                                                                    :key="index"
                                                                    :ref="'circle'"
                                                                    :class="(currentIndex === index) ? 'progress-circle start' : 'progress-circle'"
                                                                    stroke="#8a8a8a" fill="none"
                                                            />
                                                        </g>
                                                    </svg>
                                                    <div class="oder-number-item">
                                                        <div style="color: white">{{index + 1}}</div>
                                                        <div style="width: 2rem;height: 3px; background-color: #999999; margin: 0 auto;"></div>
                                                        <div style="color: white">{{epInfoList.length}}</div>
                                                    </div>
                                                </div>

                                                <div class="item-name" style="margin: 1rem auto; color: white;font-weight: bold">
                                                    {{item.name}}
                                                </div>
                                                <p class="item-describe" >{{item.suggest}}</p>
                                                <a style="margin: 1rem auto 2rem; color: white; cursor: pointer"
                                                   @click="infoShowModal(item)">{{isEn ? 'Read More' : '了解更多'}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>

                    <div class="bottom-icp" v-if="showReg">
                        <registered style="color: white"></registered>
                    </div>

                </div>

            </div>

        </div>

        <info-modal v-on:onclose="infoShowModal" :show="infoShow" :info="epInfo"></info-modal>

    </div>
</template>

<script>
    import InfoModal from "../common/InfoModal"
    import Tab from "../common/Tab"
    import 'swiper/swiper-bundle.min.css'
    import Swiper from 'swiper/swiper-bundle.min.js'
    import Registered from "../common/Registered";

    export default {
        name: "Home",
        components: {
            InfoModal,
            Tab,
            Registered
        },
        data() {
            return {
                isSmall: false,
                isEn: false,
                progress: 0,
                showStyle: 'stroke-dashoffset: ' + this.progress,
                topIndex: 0,
                infoShow: false,
                topShow: true,
                swiper: '',
                imgSwiper: '',
                contentSwiper: '',
                topSwiper: '',
                longTimer: '',
                nextTimer: '',
                progressTimer: '',
                stopAuto: false,
                currentIndex: 0,
                swiperAnim: false,
                isTimeDown: false,
                epInfoList: '',
                epInfoListLeft: '',
                epInfo: '',
                showReg: false,
            };
        },
        created() {
            this.topShow = true
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
            this.getHomeCompanyList()
        },
        mounted() {
            if (window.innerWidth < 768) {
                this.isSmall = true
            }
            const that = this
            window.onresize = () => {
                return (() => {
                    let small = (window.innerWidth < 768)
                    if (that.isSmall !== small) {
                        that.isSmall = small
                    }
                })()
            }
        },
        methods: {

            configSwiper() {
                let isIe = false
                if (!!window.ActiveXObject || "ActiveXObject" in window) {
                    isIe = true
                }

                let _this = this;
                this.swiper = new Swiper('#info-swiper', {
                    direction: 'vertical',
                    loop: false,
                    speed: 1000,
                    mousewheel: true,
                    keyboard: true,
                    slidesPerView: 1,
                    followFinger: false,
                    on: {
                        transitionEnd: function () {
                            if (this.activeIndex > 0 && this.slides.length > 1) {
                                this.removeSlide(0)
                                _this.startScroll()
                                _this.showReg = true
                            }
                        },
                        slideChange: function () {

                        },
                    },
                });

                let that = this
                setTimeout(function () {
                    that.configLoopSwiper()
                }, 500)
            },
            configLoopSwiper() {
                let _this = this;
                this.imgSwiper = new Swiper('#img-swiper', {
                    direction: 'vertical',
                    loop: true,
                    speed: 1000,
                    keyboard: true,
                    mousewheel: true,
                    controller: {
                        control: _this.contentSwiper,
                        inverse: true,
                    },
                    slidesPerView: 1,
                    followFinger: false,
                    on: {
                        touchStart: function (swiper, event) {
                            _this.contentSwiper.autoplay.stop()
                        },
                        touchEnd: function (swiper, event) {
                            _this.timerStart()
                        },
                        slideChange: function () {

                        },
                    },
                });

                this.contentSwiper = new Swiper('#content-swiper', {
                    direction: 'vertical',
                    loop: true,
                    speed: 1000,
                    autoplay: {
                        delay: 6000,
                        disableOnInteraction: false,
                    },
                    keyboard: true,
                    slidesPerView: 1,
                    mousewheel: true,
                    followFinger: false,
                    controller: {
                        control: _this.imgSwiper,
                        inverse: true,
                    },
                    on: {
                        touchStart: function (swiper, event) {
                            _this.contentSwiper.autoplay.stop()
                        },
                        touchEnd: function (swiper, event) {
                            _this.timerStart()
                        },
                        slideChange: function () {
                            _this.startProgress()
                        },
                    },
                });
                this.contentSwiper.autoplay.stop()

                this.imgSwiper.controller.control = this.contentSwiper

            },
            infoShowModal(item) {
                this.infoShow = !this.infoShow
                if (this.infoShow) {
                    this.epInfo = item
                    this.contentSwiper.autoplay.stop()
                    this.currentIndex = -1
                    this.isTimeDown = false
                } else {
                    this.startScroll()
                }
            },
            timerStart() {
                let _this = this
                clearTimeout(_this.timer);
                _this.timer = setTimeout(function () {
                    _this.isTimeDown = false
                    _this.startScroll()
                }, 15000);
                _this.isTimeDown = true;
            },
            startScroll() {
                this.contentSwiper.autoplay.start()
                this.startProgress()
            },
            next() {
                this.swiper.slideNext()
            },
            startProgress() {
                if (!this.isTimeDown) {
                    this.currentIndex = this.contentSwiper.activeIndex - 1;
                }
            },

            getHomeCompanyList() {
                this.$request.post('/children/company/listPage',
                    this.qs.stringify({
                        page: 0,
                        rows: 100,
                        language: this.isEn ? 2 : 1,
                        isShow: 1
                    }))
                    .then(res => {
                        let data = res.data.data
                        this.epInfoList = []
                        this.epInfoListLeft = []
                        for (let i in data) {
                            this.epInfoList.push(data[i])
                        }
                        
                        for (let i in this.epInfoList.reverse()) {
                            this.epInfoListLeft.push(data[i])
                        }
                        this.$forceUpdate()
                        if (this.epInfoList) {
                            let that = this
                            setTimeout(function () {
                                that.configSwiper()
                            }, 1000)

                        }
                    })
            }

        }
    }
</script>

<style lang="scss">
    @import "src/common/style/variable.scss";

    .content {
        overflow: hidden;
        width: 100%;
    }

    .bottom-icp {
        z-index: 999;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: auto;
        padding: 5px 10px;
    }

    .tab-item {
        position: absolute;
        width: 100%;
        height: calc(100% - 4rem);
        /*overflow-y: auto;*/
        margin-top: 0;
        border: 0px solid #FFFFFF;
    }

    .main {
        position: absolute;
        top: 4rem;
        bottom: 0;
        margin: 0;
        width: 100%;
        text-align: center;
        z-index: 9;
        display: flex;
    }

    .top-img-bg {
        width: 1.6rem;
        height: 1.6rem;
        margin: 5rem auto 3rem;
        display: flex;
        align-items: center
    }

    .home-main-img {
        width: 100%;
        height: 105%;
        display: block;
        overflow: hidden;
        margin-top: -5px;
        margin-bottom: -1rem;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-image: url("../assets/home_main_bg.png");
    }

    .home-main-content {
        text-align: center;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .home-info-img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .home-info-right {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
    }

    .home-info-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .home-info-content {
        width: 100%;
        height: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.7);

        &.red {
            background-color: rgba(164, 38, 41, 0.7);
        }
    }
    .item-name{
        font-size:2.2em;
    }
    .item-describe {
        margin: 2rem 2rem;
        color: white;
        text-align: left;
        font-weight:200;
        font-family: "Gotham A","Gotham B",Helvetica,Arial,"Microsoft Yahei",SimSun;
    }

    .next-icon {
        width: 2.4rem;
        height: 2.4rem;
        margin: 1.5rem auto;
    }

    @media only screen and (min-width: 768px) {
        .main {
            top: 5.8rem;
        }
        .tab-item {
            height: calc(100% - 5.8rem);
        }
        .home-info-right {
            width: 100%;
            height: 100%;
            display: block;
            overflow: hidden;
            float: right;
        }
        .home-info-left {
            width: 30%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;
            margin: 0;
            float: left;
            opacity: 0.9;
            background-color: rgba(0, 0, 0, 0.5);
        }
        .home-info-content {
            padding: 0;
            position: relative;
            margin: 0;
            background-color: $color-text-title;

            &.red {
                background-color: $color-red;
            }
        }

    }

    .title-img {
        // height: auto;
        max-height: 23vw;
        text-align: center;
        margin: 6rem auto 4rem!important;
        width:75rem;
        height:22.5rem;
        // width: auto;
        // max-width: 80%;
        object-fit: contain;
    }

    .click-tips {
        height: auto;
        max-height: 17rem;
        text-align: center;
        color: black;
        margin-top: 3rem;
        font-size: 1em;
    }
    .min-title-img-box{
        display: none;
    }
    @media only screen and (max-width: 768px) {
        .top-img-bg {
            margin: 5rem auto 1rem;
        }
        .title-img {
            // width: 45%;
            // max-width: 45%;
            // max-height: 13vw;
            display: none;
        }
        .click-tips {
            margin-top: 1rem;
            font-size:0.88rem;
        }
        .min-title-img-box{
            display: block;
            margin-bottom:4.94rem;
            .small_new_logo{
                width:6.25rem;
                height:1.74rem;
                margin:0 auto 6.25rem;
                display: block;
            }
            .small_new_logo_text{
                width:18.75rem;
                height:2.64rem;
                display: block;
                margin-bottom:0 auto;
            }
        }
    }

    @media only screen and (min-width: 1080px) {
        .top-img-bg {
            width: 60px;
            height: 60px;
        }
        .next-icon {
            width: 56px;
            height: 56px;
        }
        .click-tips {
            font-size: 16px;
        }
        .item-name{
           font-size:2.5em; 
        }
        .item-describe {
            max-width: 16em;
            font-size:1.25em;
        }
    }

    @media only screen and (min-width: 1440px) {
        .top-img-bg {
            width: 70px;
            height: 70px;
        }
        .next-icon {
            width: 66px;
            height: 66px;
        }
        .click-tips {
            font-size: 18px;
        }
    }

    @media only screen and (min-width: 1920px) {
        .top-img-bg {
            width: 80px;
            height: 80px;
        }
        .next-icon {
            width: 76px;
            height: 76px;
        }
        .click-tips {
            font-size: 20px;
        }
    }

    .timer-svg {
        transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
    }

    @-webkit-keyframes myprogress {
        0% {
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dashoffset: 150;
        }
        100% {
            stroke-dashoffset: 300;
        }

    }

    @keyframes myprogress {
        0% {
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dashoffset: 150;
        }
        100% {
            stroke-dashoffset: 300;
        }
    }

    .progress-circle {
        stroke-dasharray: 300, 300;
        stroke-dashoffset: 300;
        display: inline-block;

        &.start {
            -webkit-animation-name: myprogress;
            -webkit-animation-duration: 6s;
            -webkit-animation-timing-function: linear;
            -webkit-animation-iteration-count: 1;
            animation-name: myprogress;
            animation-duration: 6s;
            animation-timing-function: linear;
            animation-iteration-count: 1;
        }
    }

    .oder-number-item {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .swipe-item {
        height: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }


</style>
