<template>
    <div>
        <tab :index="4" ref="tab_top"></tab>
        <div style="width: 100%;overflow: hidden;">
            <div class="tab-item" ref="content" @scroll="handleScroll">
                <img ref="bg_img" src="../assets/main_job_detail_bg.png"
                     style="width: 100%; height: auto; object-fit: cover">

                <div v-for="(item, index) in jobList" :key="index" class="list">

                    <div class="job-item">
                        <div class="job-item-date">
                            <div style="color: white; font-size: 0.6em; display: block">{{getMd(item.dtUpdate)}}
                            </div>
                            <div style="color: white; font-size: 1em; display: block">{{getYear(item.dtUpdate)}}
                            </div>
                        </div>
                        <div class="job-item-info">
                            <div class="job-item-name">
                                {{item.position}}
                            </div>
                            <div style="display: flex; align-items: center; justify-content: space-between">
                                <div class="job-item-type">{{item.type === 1 ?
                                    investedN : fundN}}
                                </div>
                                <img v-if="item.isOpen" src="../assets/main_retract_icon.png" class="job-action-img"
                                     @click="showItemInfo(item)">
                                <img v-else src="../assets/main_open_icon.png" class="job-action-img"
                                     @click="showItemInfo(item)">
                            </div>
                            <div class="job-item-addr">{{item.address}}</div>
                        </div>
                    </div>

                    <div class="job-item-detail" v-if="item.isOpen">
                        <div v-html="item.content">
                        </div>
                        <!--                        <div style="font-weight: bold; font-size: 0.8em; color: #333333; margin-top: 2rem">职位描述</div>-->

                        <!--                        <div class="job-info-type">岗位职责：-->
                        <!--                            <span>1.哈的哈大好大</span>-->
                        <!--                        </div>-->

                        <!--                        <div class="job-info-type">任职要求：-->
                        <!--                            <span>1.哈的哈大好大</span>-->
                        <!--                        </div>-->

                        <!--                        <div class="job-info-type">招聘人数：-->
                        <!--                            <span>1</span>-->
                        <!--                        </div>-->

                        <!--                        <div class="job-info-type">工作地点：-->
                        <!--                            <span>1</span>-->
                        <!--                        </div>-->

                        <!--                        <div class="job-info-type">请发简历到：-->
                        <!--                            <span>hadgajahsajhdajhdajhdajsh@qqqqqq</span>-->
                        <!--                        </div>-->
                    </div>

                </div>

                <p class="bottom-tips" v-html="emailMsg">
                    

                </p>


                <div class="bottom-page-nav" style="margin-top: 1rem" v-if="rows">
                    <div class="pagination" @click="reducePage"><</div>
                    <div class="pagination" v-if="currentPage > 1" @click="selectPage(currentPage - 1)">{{currentPage -
                        1}}
                    </div>
                    <div class="pagination index" v-if="currentPage > 0" @click="selectPage(currentPage)">
                        {{currentPage}}
                    </div>
                    <div class="pagination" v-if="currentPage < rows" @click="selectPage(currentPage + 1)">{{currentPage
                        + 1}}
                    </div>
                    <div class="pagination" @click="plusPage">></div>

                </div>

                <div style="flex: 1"></div>

                <registered style="padding: 10px"></registered>

            </div>

        </div>
    </div>
</template>

<script>
    import Tab from "../common/Tab"
    import Registered from "../common/Registered";

    export default {
        name: "JobDetail",
        components: {
            Tab,
            Registered
        },
        data() {
            return {
                barH: 0,
                isEn: false,
                investedN: 'Invested',
                fundN: 'Pure',
                emailMsg:"",
                currentPage: 1,
                rows: 0,
                type: 1,
                jobList: []
            }
        },
        created() {
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
            this.type = this.$route.query.type
            this.setWord()
            this.getJobCount(this.type)
        },
        mounted() {
            this.barH = this.$refs.tab_top.$refs.bar.offsetHeight
        },
        methods: {
            handleScroll() {
                let scrollTop = this.$refs.content.scrollTop
                let bgh = this.$refs.bg_img.offsetHeight
                let al = Number((scrollTop / (bgh - this.barH)).toFixed(1))
                this.$refs.tab_top.$refs.bar.style.background = 'rgba(255, 255, 255, ' + al + ')'
            },
            setWord() {
                if (this.isEn) {
                    this.investedN = 'Invested'
                    this.fundN = 'Pure'
                    this.emailMsg= 'Contact email: hr@purefunds.cn<br/>Welcome to follow the wechat subscription number of Pure Fund. Please search 「赤子基金」or PureFunds'
                } else {
                    this.investedN = '赤子基金被投企业'
                    this.fundN = '赤子基金'
                    this.emailMsg='联系邮箱： hr@purefunds.cn <br/>欢迎您关注赤子基金微信订阅号，请搜索「赤子基金」或 PureFunds'
                }
            },
            selectPage(index) {
                this.currentPage = index
                this.getJobs(index)
            },
            reducePage() {
                if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1
                }
                this.getJobs(this.currentPage)
            },
            plusPage() {
                if (this.currentPage < this.rows) {
                    this.currentPage = this.currentPage + 1
                }
                this.getJobs(this.currentPage)
            },
            showItemInfo(item) {
                console.log(item)
                if (item.isOpen) {
                    item.isOpen = false
                } else {
                    item.isOpen = true
                }
                this.$forceUpdate()
            },

            getJobs(page) {
                this.$request.post('/children/work/listPage',
                    this.qs.stringify({
                        page: page,
                        rows: 4,
                        language: this.isEn ? 2 : 1,
                        type: this.type
                    }))
                    .then(res => {
                        console.log(res)
                        this.jobList = res.data.data
                        for (let i in this.jobList) {
                            this.jobList[i].isOpen = false
                        }
                    })
            },
            getJobCount(type) {
                this.$request.post('/children/work/total',
                    this.qs.stringify({
                        language: this.isEn ? 2 : 1,
                        type: type
                    }))
                    .then(res => {
                        this.rows = Math.ceil(res.data.data / 4)
                        this.getJobs(0)
                    })
            },
            getYear(time) {
                if (time.length > 4) {
                    return time.substring(0, 4)
                }
                return time
            },
            getMd(time) {
                if (time.length >= 10) {
                    return time.substring(5, 10)
                }
                return time
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/common/style/variable";

    .tab-item {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        margin-top: 0;
    }

    .pagination {
        border: 1px solid #DDDDDD;
        border-radius: 0.1rem;
        -webkit-border-radius: 0.1rem;
        -moz-border-radius: 0.1rem;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        color: #999999;
        font-size: 1em;
        cursor: pointer;
        margin: 0.2rem;

        @media only screen and (min-width: 768px) {
            &:hover {
                border: 1px solid $color-red;
                color: $color-red;
            }
        }

        &.index {
            border: 1px solid $color-red;
            color: $color-red;
        }
    }

    .list {
        padding: 0 2rem;
    }
    .bottom-tips {
        padding: 0 2rem;
        font-size: 14px;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .job-item {
        width: 100%;
        height: 5.1rem;
        margin: 1rem 0;
        display: flex;
    }

    .job-item-date {
        width: 4.1rem;
        height: 100%;
        background-color: $color-red;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .job-item-info {
        flex: 1;
        height: 100%;
        background-color: $color-bg-dark;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        padding: 0 1.2rem;
    }

    .job-action-img {
        width: 1.2rem;
        height: 1.2rem;
        object-fit: cover;
    }

    .job-info-type {
        font-size: 0.8em;
        color: $color-black;
        margin-top: 0.6rem;
        font-weight: 500;

        & span {
            color: $color-text-content;
            font-weight: normal;
            line-height: 2em
        }
    }

    .job-item-name {
        font-size: 1em;
        color: #333333;
        font-weight: bold;
        text-align: left;
    }

    .job-item-type {
        font-size: 0.8em;
        color: #999999;
        text-align: left
    }

    .job-item-addr {
        font-size: 0.8em;
        color: #666666;
        text-align: left
    }

    .job-item-detail {
        text-align: left;
        margin-bottom: 2rem;
    }

    .bottom-page-nav {
        text-align: center;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
    }


    @media only screen and (min-width: 768px) {
        .list {
            max-width: 660px;
            width: 660px;
            margin: 0 auto;
        }
        .job-item-detail {
            margin-left: 5.3rem;
            margin-right: 2.4rem;
        }
        .bottom-tips {
            max-width: 660px;
            width: 660px;
            margin: 2rem auto;
        }
    }

    @media only screen and (min-width: 1920px) {
        .list {
            max-width: 1000px;
            width: 1000px;
        }
        .job-item-detail {
            margin-left: 7.4rem;
        }
        .job-item-name {
            font-size: 1.5em;
        }
        .job-item-addr {
            font-size: 1.2em;
        }
        .job-item-type {
            font-size: 1.2em;
        }
        .job-item {
            height: 7.7rem;
        }
        .job-item-date {
            width: 6.2rem;
        }
        .bottom-tips {
            max-width: 1000px;
            width: 1000px;
            font-size: 20px;
            margin: 3rem auto;
        }
    }
</style>