<template>
    <div>
        <tab :index="5" ref="tab_top"></tab>
        <div style="width: 100%;overflow: hidden; ">
            <div class="tab-item" ref="content" @scroll="handleScroll">
                <img ref="bg_img" src="../assets/main_about_bg.png" class="top-img">

                <div style="width: 100%; height: 100%; text-align: left; padding: 0 2rem;">


                    <div class="content">


                        <div class="left-city">

                            <div style="font-size: 1em; color: #333333; font-weight: bold; margin-top: 3rem; margin-bottom: 1rem">
                                {{isEn ? 'Area' : '地区'}}
                            </div>
                            <div v-for="(item, index) in cityList" class="list-city"
                                 :class="choseIndex === index ? 'chose' : ''" @click="choseCity(item, index)">
                                {{item.area}}
                            </div>
                        </div>

                        <div class="right-city-info" v-if="choseItem">

                            <p style="font-size: 2em; color: #333333; font-weight: bold; display: block; margin: 2rem 0 1rem;">{{choseItem.area}}</p>

                            <div style="font-size: 1.3em; color: #333333; display: block; line-height: 1.5em" v-html="choseItem.address"></div>

                            <div style="display: flex; flex-direction: row; margin-top: 3rem">
                                <div style="width: 1.4rem; height: 1.4rem;">
                                    <img src="../assets/about_tel_icon.png"
                                         style="width: 1.4rem; height: 1.4rem; object-fit: cover;">
                                </div>
                                <a style="font-size: 1.1em; color: #333333; flex: 1; margin-left: 0.5rem">{{choseItem.phone}}</a>
                            </div>

                        </div>
                    </div>

                    <div style="margin: 3rem auto; width: 3rem; height: auto">
                        <img src="../assets/logo.png" style="width: 100%; margin-bottom: 2rem">
                    </div>

                    <registered style="padding: 10px"></registered>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import Tab from "../common/Tab"
    import Registered from "../common/Registered";

    export default {
        name: "AboutUs",
        components: {
            Tab,
            Registered
        },
        data() {
            return {
                barH: 0,
                isEn: false,
                choseIndex: -1,
                choseItem: '',
                cityList: [],
            }
        },
        created() {
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
            this.getList()
        },
        mounted() {
            this.barH = this.$refs.tab_top.$refs.bar.offsetHeight
        },
        methods: {
            handleScroll() {
                let scrollTop = this.$refs.content.scrollTop
                let bgh = this.$refs.bg_img.offsetHeight
                let al = Number((scrollTop / (bgh - this.barH)).toFixed(1))
                this.$refs.tab_top.$refs.bar.style.background = 'rgba(255, 255, 255, ' + al + ')'
            },
            choseCity(item, index) {
                this.choseIndex = index;
                this.choseItem = item;
            },
            getList() {
                this.$request.post('/children/contact/listPage',
                    this.qs.stringify({
                        page: 0,
                        rows: 100,
                        language: this.isEn ? 2 : 1
                    }))
                    .then(res => {
                        this.cityList = res.data.data
                        this.choseIndex = 0
                        this.choseItem = this.cityList[0]
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/common/style/variable.scss";

    .tab-item {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        margin-top: 0;
    }

    .list-city {
        font-size: 18px;
        color: $color-text-content;
        line-height: 2em;
        cursor: pointer;

        &.chose {
            color: $color-red;
        }
    }

    .right-city-info,
    .left-city {
        width: 100%;
        position: relative;
    }

    .content {
        min-height: 60vh;
        height: auto;
        display: list-item;
    }

    .top-img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    @media only screen and (min-width: 768px) {
        .content {
            display: flex;
            flex-direction: row;
        }
        .left-city {
            width: auto;
            min-width: 8rem;
            max-width: 12rem;
            margin-left: 10%;
        }
        .right-city-info {
            flex: 1;
            padding-top: 2rem;
            margin-left: 2rem;
        }
        .top-img {
            width: 100%;
            height: 34vw;
            object-fit: cover;
        }
    }
</style>