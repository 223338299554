<template>
    <div>
        <div class="top-bar" ref="bar">
            <div class="top-log" @click="reloadPage">
                <img src="../assets/home_left_logo.png" style="width: 100%; height: auto;"/>
            </div>
            <ul class="nav">
                <li :class="index === 0 ? 'active' : ''" @click="topIndexChose(0)">{{tabNames[0]}}</li>
                <li :class="index === 1 ? 'active' : ''" @click="topIndexChose(1)">{{tabNames[1]}}</li>
                <li :class="index === 2 ? 'active' : ''" @click="topIndexChose(2)">{{tabNames[2]}}</li>
                <li :class="index === 3 ? 'active' : ''" @click="topIndexChose(3)">{{tabNames[3]}}</li>
                <li :class="index === 6 ? 'active' : ''" @click="topIndexChose(6)">{{tabNames[6]}}</li>
                <li :class="index === 4 ? 'active' : ''" @click="topIndexChose(4)">{{tabNames[4]}}</li>
                <li :class="index === 5 ? 'active' : ''" @click="topIndexChose(5)">{{tabNames[5]}}</li>
                <li style="background: none !important;" @click="changeLanguage">{{isEn ? '中文' : 'EN'}}</li>
            </ul>
            <div class="menu" @click="menuShowModal">
                <img src="../assets/home_right_menu.png" style="width: 100%; height: auto;"/>
            </div>
        </div>

        <menu-modal v-if="menuShow" :index="index" v-on:onclose="menuShowModal"
                    v-on:onIndex="topIndexChose"></menu-modal>
    </div>
</template>

<script>
    import MenuModal from "../common/menuModal";

    export default {
        name: "Tab",
        components: {
            MenuModal
        },
        props: {
            index: 0
        },
        data() {
            return {
                menuShow: false,
                isEn: false,
                tabNamesZh: [
                    '首页',
                    '赤子团队',
                    '发展动态',
                    '被投企业',
                    '工作机会',
                    '联系我们',
                    '赤子创服'
                ],
                tabNamesEn: [
                    'Home',
                    'People',
                    'Build',
                    'Companies',
                    'Jobs',
                    'Contact',
                    'PureValue'
                ],
                tabNames: []
            }
        },
        watch: {},
        mounted() {
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
            this.setNames()
        },
        methods: {
            reloadPage() {
                if (this.index === 0) {
                    window.location.reload()
                } else {
                    this.topIndexChose(0)
                }
            },
            setNames() {
                if (this.isEn) {
                    this.tabNames = this.tabNamesEn
                } else {
                    this.tabNames = this.tabNamesZh
                }
            },
            changeLanguage() {
                if (this.isEn) {
                    this.$router.push({
                        path: this.common.PAGE_HOME
                    })
                } else {
                    this.$router.push({
                        path: this.common.PAGE_EN_HOME
                    })
                }
                this.isEn = !this.isEn
                this.setNames()
            },
            topIndexChose(index) {
                let isEn = false
                if (this.common.languageType(this) === this.common.LANGUAGE_EN) {
                    isEn = true
                }
                this.menuShow = false;
                this.topIndex = index;
                if (index === 0) {
                    if (isEn) {
                        this.$router.push({
                            path: this.common.PAGE_EN_HOME
                        })
                    } else {
                        this.$router.push({
                            path: this.common.PAGE_HOME
                        })
                    }

                } else if (index === 1) {
                    if (isEn) {
                        this.$router.push({
                            path: this.common.PAGE_EN_TEAM
                        })
                    } else {
                        this.$router.push({
                            path: this.common.PAGE_TEAM
                        })
                    }
                } else if (index === 2) {
                    if (isEn) {
                        this.$router.push({
                            path: this.common.PAGE_EN_NEWS
                        })
                    } else {
                        this.$router.push({
                            path: this.common.PAGE_NEWS
                        })
                    }
                } else if (index === 3) {
                    if (isEn) {
                        this.$router.push({
                            path: this.common.PAGE_EN_ENTERPRISE
                        })
                    } else {
                        this.$router.push({
                            path: this.common.PAGE_ENTERPRISE
                        })
                    }
                } else if (index === 4) {
                    if (isEn) {
                        this.$router.push({
                            path: this.common.PAGE_EN_JOB
                        })
                    } else {
                        this.$router.push({
                            path: this.common.PAGE_JOB
                        })
                    }
                } else if (index === 5) {
                    if (isEn) {
                        this.$router.push({
                            path: this.common.PAGE_EN_ABOUT_US
                        })
                    } else {
                        this.$router.push({
                            path: this.common.PAGE_ABOUT_US
                        })
                    }
                }else if (index === 6) {
                    if (isEn) {
                        window.open('https://purefunds.cn/enPureValue/', '_self')
                    } else {
                        window.open('https://purefunds.cn/PureValue/', '_self')
                    }
                } 
                 else {
                    if (isEn) {
                        this.$router.push({
                            path: this.common.PAGE_EN_404
                        })
                    } else {
                        this.$router.push({
                            path: this.common.PAGE_404
                        })
                    }
                }
            },
            menuShowModal() {
                this.menuShow = !this.menuShow;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/common/style/variable.scss";

    .menu {
        width: 1.5rem;
        margin-right: 1.8rem;
    }

    .top-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        z-index: 999;
        justify-content: space-between;
        width: 100%;
        height: 4rem;
    }

    .top-log {
        width: 6rem;
        padding: 0.5rem;
        margin-left: 1rem;
        cursor: pointer;
    }

    .nav {
    }

    .nav li {
        background: none;
        margin: 0.2rem;
        min-width: 5.8rem;
        color: black;
        height: 1.8em;
        font-size: 18px;
        line-height: 1.8em;
        cursor: pointer;
        text-align: center;

        &:hover {
            color: $color-red;
        }

        &.active {
            color: $color-red;
            background-image: url("../assets/home_nav_bg.png");
            -webkit-background-size: 100% 100%;
            -moz-background-size: 100% 100%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }


    @media only screen and (min-width: 768px) {
        .menu {
            display: none;
        }
        .top-bar {
            height: 5.8rem;
        }
        .top-log {
            width: 9rem;
        }
    }

    @media only screen and (max-width: 767px) {
        .nav {
            display: none;
            visibility: hidden;
            width: 0;
            height: 0;
        }
    }
</style>