<template>
    <div>
        <tab :index="1"></tab>
        <div style="width: 100%;overflow: hidden;">
            <div class="tab-item" style="width: 100%" v-if="detail" >
                <div style="height: 100%">

                    <div class="top-bg">
                        <img :src="detail.pic" style="width: 100%; height: 100%; object-fit: cover">
                        <div class="top-name" ref="topName" :class="showAnim ? 'show' : ''">{{detail.name}}</div>
                    </div>

                    <div class="content" :class="showAnim ? 'show' : ''">
                        <div class="name">{{detail.name}}</div>
                        <div class="title" v-if="detail.title">{{detail.title}}</div>
                        <div class="describe">{{detail.profile}}</div>

                    </div>

                    <div style="margin: 0 auto; width: 3rem; height: auto">
                        <img src="../assets/logo.png" style="width: 100%; margin-bottom: 2rem">
                    </div>

<!--                    <div style="flex: 1;"></div>-->

<!--                    <registered style="padding: 10px"></registered>-->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FontFaceobserver from 'fontfaceobserver'
    import Tab from "../common/Tab"
    import Registered from "../common/Registered";

    export default {
        name: "TeamMemberDetail",
        components: {
            Tab,
            Registered
        },
        data() {
            return {
                showAnim:false,
                detail: '',
                id: ''
            }
        },
        created() {
            this.id = this.$route.query.id
            this.getMemberInfo(this.id)
            this.loadFont()

        },
        methods: {
            loadFont(){
                var font = new FontFaceobserver('隶书')
                font.load().then(()=>{
                    this.$refs.topName.style.fontFamily = '隶书'
                })
            },
            getMemberInfo(id) {
                this.showAnim = false
                this.$request.post('/children/team/getById',
                    this.qs.stringify({
                        id: id
                    }))
                    .then(res => {
                        this.detail = res.data.data
                        let that = this
                        setTimeout(function () {
                            that.showAnim = true
                        }, 500)
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../common/style/variable";

    @font-face {
        font-family: '隶书';
        src: url("../common/style/隶书.ttf")format('truetype');
    }
    .tab-item {
        position: absolute;
        width: 100%;
        height: calc(100% - 4rem);
        overflow-y: auto;
        margin-top: 4rem;
    }

    .top-name {
        font-size: 1.4em;
        color: $color-black;
        display: flex;
        position: absolute;
        font-weight: 500;
        justify-content: center;
        align-items: center;
        top: 0;
        bottom: 0;
        left: 56%;
        font-family: '隶书';
        transform: translateY(50px);
        opacity: 0;
        transition: all 0s ease 0s;
        &.show {
            transform: translateY(0);
            opacity: 1;
            transition: all .5s ease 0s;
        }
    }

    .name {
        margin: 4rem 2rem;
        font-size: 1.4em;
        color: $color-black;
        font-weight: bold;
        text-align: center;
        display: none;
    }

    .title {
        margin: 2rem 2rem 0;
        font-size: 1em;
        color: $color-black;
        font-weight: bold;
    }

    .content {
        position: relative;
        width: 100%;
        text-align: left;
        min-height: 60vh;
        transform: translateY(50px);
        opacity: 0;
        transition: all 0s ease 0s;
        &.show {
            transform: translateY(0);
            opacity: 1;
            transition: all .5s ease 0s;
        }
    }

    .top-bg {
        width: 100%;
        position: relative;
        height: 39vw;
    }

    .describe {
        margin: 2rem 2rem;
        font-size: 1em;
        color: $color-text-title;
    }

    @media only screen and (min-width: 768px) {
        .tab-item {
            height: calc(100% - 5.8rem);
            margin-top: 5.8rem;
        }
        .content {
            min-width: 600px;
            max-width: 800px;
            text-align: left;
            margin: 0 auto;
        }
        .title {
            margin: 2rem 2rem 0;
            font-size:20px;
        }
        .describe {
            margin: 2rem 2rem;
            font-size:20px;
        }
        .top-bg {
            width: 100%;
            position: relative;
            height: 39vw;
        }
        .top-name {
            display: flex;
            position: absolute;
            font-size: 40px;
            color: $color-black;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            top: 0;
            bottom: 0;
            right: 20%;
            font-family: '隶书';
        }
        .name {
            display: none;
        }
    }

    @media only screen and (min-width: 1080px) {
        .top-name {
            font-size: 50px;
        }
    }

    @media only screen and (min-width: 1440px) {
        .top-name {
            font-size: 60px;
        }
    }

    @media only screen and (min-width: 1920px) {
        .top-name {
            font-size: 70px;
        }
    }
</style>