<template>
    <div :class="show ? 'modal-backdrop fade show' : 'modal-backdrop'" tabindex="-1" role="dialog" :show="setOpen"
         :info="setInfo">
        <div :class="show ? 'modal-dialog show' : 'modal-dialog'" role="document">
            <div class="modal-content">

                <div class="i-close">
                    <div class="i-img-close" @click="closeModal()"></div>
                </div>

                <div :class="show ? 'right-content show' : 'right-content'" ref="r_anim" v-if="detailInfo">

                    <div style="margin: 2rem auto 0; width: 100%; text-align: center">
                        <img :src="detailInfo.url" class="info-icon">
                    </div>
                    <div class="info-title">{{detailInfo.name}}</div>

                    <a :href="detailInfo.website" target="_blank" style="text-decoration:none;color:#333">
                        <div class="info-content content-div" v-html="detailInfo.content"></div>
                    </a>

                    <p class="website" v-if="isEn && detailInfo.website" @click="toWeb(detailInfo.website)">
                        Website:{{detailInfo.website}}</p>
                    <p class="website" v-if="!isEn && detailInfo.website" @click="toWeb(detailInfo.website)">
                        公司网站:{{detailInfo.website}}</p>
                    <div style="width: 100%; height: auto;margin: 2rem 0">
                        <img class="info-image" :src="detailInfo.photo">
                    </div>

                </div>

            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "InfoModel",
        props: {
            show: false,
            info: ''
        },
        data() {
            return {
                isEn: false,
                isOpen: false,
                detailInfo: ''
            }
        },
        mounted() {
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
        },
        watch: {
            info: {
                handler(newValue, oldValue) {
                    console.log(newValue)
                    this.getDetail(newValue.id)
                },
                deep: true
            }
        },
        methods: {
            closeModal() {
                this.$emit("onclose", true)
            },
            setOpen(open) {
                this.isOpen = open;
            },
            setInfo(info) {

            },
            getDetail(id) {
                this.$request.post('/children/company/getById',
                    this.qs.stringify({
                        id: id,
                    }))
                    .then(res => {
                        // console.log(res)
                        this.detailInfo = res.data.data
                    })
            },
            toWeb(url) {
                try {
                    if (url.startsWith('http')) {
                        window.open(url)
                    } else {
                        window.open('http://' + url)
                    }
                } catch (e) {
                    if (url.startsWith('http')) {
                        window.location.href = url
                    } else {
                        window.location.href = 'http://' + url
                    }
                }


            }

        },

    }
</script>

<style scoped lang="scss">
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1040;
        width: 100vw;
        height: 100vh;
        pointer-events: none;
        background-color: rgba(255, 255, 255, 0.7);
    }

    .modal-backdrop {
        opacity: 0;
        transition: opacity 0.3s linear;

    }

    .modal-backdrop.show {
        opacity: 1;
        pointer-events: auto;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1050;
        display: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        outline: 0;
    }

    .modal-dialog {
        position: fixed;
        margin: auto;
        max-width: 100%;
        width: 100%;
        height: 100%;
        right: 0;
        opacity: 0;
        transform: translateX(100vw);
        -ms-transform: translateX(100vw);
        -moz-transform: translateX(100vw);
        -webkit-transform: translateX(100vw);
        -o-transform: translateX(100vw);
        transition: all .3s;

        &.show {
            opacity: 1;
            transform: translateX(0);
            -ms-transform: translateX(0);
            -moz-transform: translateX(0);
            -webkit-transform: translateX(0);
            -o-transform: translateX(0);
        }
    }

    .modal.fade.show .modal-dialog {
        right: -100%;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }

    .modal.fade .modal-dialog {
        transition: transform 0.3s ease-out;
        transform: translate(0, -50px);
    }

    @media (prefers-reduced-motion: reduce) {
        .modal.fade .modal-dialog {
            transition: none;
        }
    }

    .modal.show .modal-dialog {
        transform: none;
    }

    .modal.modal-static .modal-dialog {
        transform: scale(1.02);
    }

    .modal-content {
        width: 100%;
        height: 100%;
        background-color: white;
        background-clip: padding-box;
        border: 0px solid rgba(0, 0, 0, 0);
        border-radius: 0;
        outline: 0;
        overflow-y: auto;
    }

    .info-icon {
        width: 8rem;
        object-fit: cover;
    }

    .right-content {
        width: 100%;
        text-align: center;
        background-color: white;
    }

    .info-image {
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    .info-title {
        margin: 2rem auto;
        color: black;
        font-weight: bold;
        font-size: 32px;
    }

    .info-content {
        margin: 0 2rem;
        overflow: hidden;
        word-break: break-all;
        word-wrap: break-word;
        height: auto;
        text-align: left;
        font-size: 1em;
        line-height: 25px;
        font-weight: 200;
        font-family: "Gotham A","Gotham B",Helvetica,Arial,"Microsoft Yahei",SimSun;
    }

    .i-close {
        width: 3rem;
        height: 3rem;
        margin: 4rem auto 0;
    }

    .i-img-close {
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-image: url("../assets/home_close.png");
    }

    .i-img-close:active,
    .i-img-close:hover {
        background-image: url("../assets/home_close_on.png");
    }

    .website {
        margin: 3rem 2rem; cursor: pointer;
        font-size:1em;
        font-weight: 200;
        font-family: "Gotham A","Gotham B",Helvetica,Arial,"Microsoft Yahei",SimSun;
        // &:hover {
        //     border-bottom: 1px solid #000000;
        // }
    }

    @media only screen and (max-width: 767px) {
        .modal-dialog {
            max-width: 100%;
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .modal-content {
            overflow: hidden;
            background-color: rgba(0, 0, 0, 0);
        }
        .right-content {
            width: 70%;
            height: 100%;
            position: fixed;
            right: 0;
            overflow-y: auto;
            pointer-events: none;

            &.show {
                pointer-events: auto;
            }
        }

        .info-content {
            margin: 0 15%;
        }

        .i-close {
            width: 4rem;
            height: 4rem;
            position: fixed;
            top: 6rem;
            right: calc(70% - 2rem);
            z-index: 1041;
        }
        .i-img-close {
            width: 4rem;
            height: 4rem;
        }

    }

    @media only screen and (min-width: 1080px) {
        .info-title {
            font-size: 40px;
        }
        .info-icon {
            width: 10rem;
            margin-top: 2rem;
        }
        .info-content {
            font-size: 1.25em;
            line-height:32px;
        }
        .website{
            font-size: 1.25em;
        }
    }

    @media only screen and (min-width: 1440px) {
        .info-title {
            font-size: 50px;
        }
        .info-icon {
            width: 11rem;
            margin-top: 3rem;
        }
    }

    @media only screen and (min-width: 768px) {
        .info-title {
            font-size: 42px;
        }
        .info-icon {
            width: 12rem;
            margin-top: 4rem;
        }
    }


</style>