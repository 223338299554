<template>
    <div>
        <tab :index="1" ref="tab_top"></tab>
        <div style="width: 100%;overflow: hidden;">
            <div class="tab-item" ref="content" @scroll="handleScroll">

                <div class="top-title-bg" ref="bg_img">
                    <img src="../assets/main_team_bg.png" style="width: 100%; height: 100%;object-fit: cover">
                    <div class="top-title-item">

                        <div :class="showTeam ? 'top-title red' : 'top-title'" @click="switchInfo(true)">{{isEn ? 'IPMT'
                            : '管理团队'}}
                        </div>
                        <div :class="!showTeam ? 'top-title red top' : 'top-title top'"
                             @click="switchInfo(false)">{{isEn ? 'ABOUT' : '关于赤子'}}
                        </div>
                    </div>
                </div>

                <div style="position: relative; min-height: 80vh" v-if="showTeam">
                    <div class="top-describe" :class="teamAnim ? 'show' : ''" v-html="describe"></div>

                    <div class="list">


                        <div class="list-member" v-if="list" v-for="(item, index) in list" :key="index"
                             @click="toDetail(item)">
                            <div class="list-image-box">
                                <b-img-lazy :src="item.photoUrl" class="list-image" :class="teamAnim ? 'show' : ''"
                                            blank-color="#F7F7F7" offset="0"/>
                            </div>
                            <div class="list-content">
                                <p class="list-name">{{item.name}}</p>
                                <div class="list-action">{{isEn ? 'Introduction' : '个人介绍'}}</div>
                            </div>

                        </div>

                    </div>

                </div>

                <div v-else style="position: relative; min-height: 80vh">
                    <div :class="usAnim ? 'item-for-us show' : 'item-for-us'">

                        <div style="margin-top: 4rem" v-for="(item, index) in forUsList">
                            <div class="for-us-title" style="margin: 0 0 4rem" v-html="item.title">
                            </div>

                            <div class="for-us-describe" v-html="item.content">
                            </div>

                        </div>

                        <div style="margin: 0 auto; width: 8rem;">
                            <img src="../assets/no/qr.jpg"
                                 style="height: auto; width: 100%; object-fit: cover; margin: 4rem auto 2rem">
                        </div>


                    </div>

                </div>

                <div style="margin: 2rem auto; width: 3rem; height: auto">
                    <img src="../assets/logo.png" style="width: 100%; margin-bottom: 2rem">
                </div>

                <registered style="padding: 10px; position: relative; bottom: 0"></registered>
            </div>
        </div>
    </div>
</template>

<script>
    import Tab from "../common/Tab"
    import Registered from "../common/Registered";

    export default {
        name: "Team",
        components: {
            Tab,
            Registered
        },
        beforeRouteLeave(to, from, next) {
            let top = 0;
            if (to.path === this.common.PAGE_TEAM_MEMBER || to.path === this.common.PAGE_EN_TEAM_MEMBER) {
                top = this.scrollTop
            }
            sessionStorage.setItem("team_scroll", top)
            next()
        },
        data() {
            return {
                scrollTop: 0,
                barH: 0,
                isEn: false,
                showTeam: true,
                describe: '',
                describeAbout: '',
                list: '',
                forUsList: '',
                teamAnim: false,
                usAnim: false,
            }
        },
        created() {
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
            this.getTeams()
            this.getTeamTitle()
            this.getTeamAboutTitle()
        },
        activated() {
            this.$refs.content.scrollTop = sessionStorage.getItem("team_scroll")
            sessionStorage.setItem("team_scroll", 0)
        },
        mounted() {
            this.barH = this.$refs.tab_top.$refs.bar.offsetHeight
        },
        methods: {
            handleScroll() {
                this.scrollTop = this.$refs.content.scrollTop
                let bgh = this.$refs.bg_img.offsetHeight
                let al = Number((this.scrollTop / (bgh / 2)).toFixed(1))
                this.$refs.tab_top.$refs.bar.style.background = 'rgba(255, 255, 255, ' + al + ')'
            },
            toDetail(item) {
                if (this.common.languageType(this) === this.common.LANGUAGE_EN) {
                    this.$router.push({
                        path: this.common.PAGE_EN_TEAM_MEMBER,
                        query: {id: item.id}
                    })
                } else {
                    this.$router.push({
                        path: this.common.PAGE_TEAM_MEMBER,
                        query: {id: item.id}
                    })
                }

            },
            switchInfo(show) {
                this.showTeam = show

                let that = this
                setTimeout(function () {
                    that.teamAnim = show
                    that.usAnim = !show;
                }, 500)
            },
            getTeams() {
                this.$request.post('/children/team/listPage',
                    this.qs.stringify({
                        page: 0,
                        rows: 100,
                        language: this.isEn ? 2 : 1
                    }))
                    .then(res => {
                        this.list = res.data.data
                        this.switchInfo(true)
                    })
            },
            getTeamTitle() {
                this.$request.post('/children/article/getById',
                    this.qs.stringify({
                        type: 1,
                        language: this.isEn ? 2 : 1
                    }))
                    .then(res => {
                        this.describe = res.data.data.content
                    })
            },
            getTeamAboutTitle() {
                this.$request.post('/website/about/listPage',
                    this.qs.stringify({
                        page: 0,
                        rows: 100,
                        language: this.isEn ? 2 : 1
                    }))
                    .then(res => {
                        this.forUsList = res.data.data
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/common/style/variable";

    .tab-item {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        margin-top: 0;
        display: list-item;
    }

    .top-title-bg {
        height: 16rem;
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    .top-title-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .top-bg {
        position: relative;
    }

    .top-title {
        font-size: 1.5em;
        color: $color-black;
        font-weight: bold;
        cursor: pointer;
        width: auto;
        margin-top: 0;
        opacity: 1;
        transform: translateY(0);

        &:hover {
            color: $color-red;
        }

        &.red {
            color: $color-red;
        }

        &.top {
            margin-top: 2rem;
        }
    }

    .top-describe {
        margin: 2.5rem auto;
        font-size: 14px;
        text-align: center;
        color: $color-text-title;
        line-height: 1.9em;
        font-weight: 500;
        max-width: 964px;
        padding: 0 2rem;
        transform: translateY(50px);
        opacity: 0;
        transition: all 0s ease 0s;

        &.show {
            transform: translateY(0);
            opacity: 1;
            transition: all .5s ease 0s;
        }
    }

    .for-us-title {
        text-align: left;
        // font-size: 1.1em;
        font-weight: bold;
        // color: black;
        width: 100%;
    }

    .list {
        width: 100%;
    }

    .list-image-box {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        margin: 0;
        overflow: hidden;
        position: relative;
        display: block;
    }

    .list-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transition: opacity 1s ease-out;

        &.show {
            opacity: 1;
        }
    }

    .list-member {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        word-wrap: break-word;
        word-break: break-all;
        overflow: hidden;
        height: auto;
    }



    .list-content {
        width: 100%;
    }

    .list-name {
        width: 100%;
        margin: 0;
        padding: 0.8rem 20%;
        text-align: center;
        font-weight: 500;
        font-size: 1.6em;
        color: $color-black
    }

    .list-action {
        display: none;
    }

    .item-for-us {
        width: 100%;
        height: 100%;
        padding: 0;
        display: block;
        margin: 0 auto;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        background-image: url("../assets/team_for_us.png");
        transform: translateY(50px);
        opacity: 0;
        transition: all 0s ease-out;
        visibility: visible;

        &.show {
            transform: translateY(0);
            opacity: 1;
            transition: all .5s ease-out;
        }
    }

    .for-us-describe {
        font-size: 1em;
        color: $color-text-title;
        margin: 4rem 2rem 0;
        font-weight:200;
        font-family: "Gotham A","Gotham B",Helvetica,Arial,"Microsoft Yahei",SimSun;
    }

    .for-us-title {
        width: 100%;
        padding: 0 2rem;
        font-size: 1.5em;
    }

    @media only screen and (min-width: 768px) {
        .top-title-bg {
            height: 19vw;
        }

        .top-title-item {
            flex-direction: row;
            padding-top: 10vw;
        }

        .top-title {
            margin-right: 1rem;
            font-size: 2.2rem;
            margin-top: 0;

            &.top {
                margin-left: 1rem;
                margin-top: 0;
            }
        }
        .list {
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
        }
        .list-member {
            width: 33.33%;
            box-sizing: border-box;
            position: relative;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            height: auto;
        }
        .list-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .list-content {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            text-align: center;
            opacity: 0;

            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.5);
            transition: opacity 0.3s ease-out .3s;

            &:hover {
                opacity: 1;
            }
        }
        .list-name {
            position: absolute;
            left: 0;
            bottom: 124px;
            margin: 0 auto;
            text-align: center;
            font-weight: bold;
            font-size: 1.2em;
            color: $color-white
        }
        .list-action {
            position: absolute;
            margin: 0 auto;
            bottom: 80px;
            left: 0;
            right: 0;
            width: 6em;
            font-size: 0.6em;
            font-weight: bold;
            color: $color-white;
            padding: 3px 0;
            border: 2px solid $color-white;
            cursor: pointer;
            text-align: center;
            display: block;

            &:hover {
                color: $color-red;
                border: 2px solid $color-red;
            }
        }
        .item-for-us {
            width: 90%;
            max-width: 800px;
            margin: 0 auto;
            padding: 0;
        }
        .for-us-describe {
            margin: 4rem 5% 0;
        }
        .for-us-title {
            width: 100%;
            padding: 0 5%;
        }

    }

    @media only screen and (min-width: 1080px) {
        .list-member {
            width: 25%;
        }
        .item-for-us {
            width: 1000px;
            max-width: 1000px;
            margin: 0 auto;
            padding: 0;
        }
        .for-us-describe {
            margin: 4rem 5% 0;
            font-size: 1.25em;
        }
        .for-us-title {
            font-size: 30px;
        }
        .list-name {
            font-size: 19px;
        }
        .list-action {
            font-size: 0.6em;
        }
    }

    @media only screen and (min-width: 1440px) {
        .list-member {
            width: 25%;
        }
        .for-us-describe {
            margin: 4rem 8% 0;
        }
        .item-for-us {
            width: 1200px;
            max-width: 1200px;
            margin: 0 auto;
            padding: 0;
        }
         .for-us-title {
            padding: 0 8%;
        }
        .list-name {
            font-size: 24px;
        }
        .list-action {
            font-size: 0.8em;
            width: 7em;
        }
    }

    @media only screen and (min-width: 1920px) {
        .item-for-us {
            width: 80%;
            max-width: 1440px;
            margin: 0 auto;
        }
        .for-us-title {
            padding: 0 10%;
        }
        .for-us-describe {
            margin: 4rem 10% 0;
        }
        .list-name {
            font-size: 33px;
        }
        .list-action {
            font-size: 0.9em;
            width: 7.5em;
        }
    }

</style>