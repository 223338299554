<template>
    <div>
        <tab :index="2" ref="tab_top"></tab>
        <div style="width: 100%;overflow: hidden;">


            <div class="tab-item" ref="content" @scroll="handleScroll">
                <div style="position: relative">
                    <img ref="bg_img" src="../assets/main_news_bg.png"
                         class="top-img"/>
                    <div class="top-title-item">
                        <div v-if="isEn" class="top-title red">NEWS&ACTIVITY&<br>POINT OF VIEW</div>
                        <div v-else class="top-title red">新闻&活动&观点</div>
                    </div>
                </div>
                <div  style="position: relative; display: flex; flex-direction: column;">

                    <div v-if="list" v-for="(item, index) in list" :key="index" ref="item" @click="toDetail(item)" 
                         :class="getItemClass(index)" >
                         <div class="item-box" :class="item.showAnim ? 'show' : ''">

                            <div :class="index % 2 === 0 ? 'list-name' : 'list-name black'">{{getItemName(item)}}</div>

                            <div :class="index % 2 === 0 ? 'list-title' : 'list-title black'">{{item.title}}</div>

                            <div :class="index % 2 === 0 ? 'list-action' : 'list-action black'">{{isEn ? 'Read More' :
                                '了解更多'}}
                            </div>
                         </div>
                    </div>

                    <div  style="flex: 1"></div>

                    <div  style="margin: 2rem auto; width: 3rem; height: auto">
                        <img src="../assets/logo.png" style="width: 100%; margin-bottom: 2rem">
                    </div>

                    <registered  style="padding: 10px"></registered>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import Tab from "../common/Tab"
    import Registered from "../common/Registered";

    export default {
        name: "News",
        components: {
            Tab,
            Registered,
        },
        beforeRouteLeave(to, from, next) {
            let top = 0;
            if (to.path === this.common.PAGE_NEWS_DETAIL || to.path === this.common.PAGE_EN_NEWS_DETAIL) {
                top = this.scrollTop
            }
            sessionStorage.setItem("news_scroll", top)
            next()
        },
        data() {
            return {
                // showAnim: false,
                scrollTop: '',
                barH: 0,
                isEn: false,
                list: '',
                types: [],
                typesEn: ['NEWS', 'ACTIVITY', 'VIEWPOINT'],
                typesZh: ['新闻', '活动', '观点']
            }
        },
        created() {
            this.isEn = this.common.languageType(this) === this.common.LANGUAGE_EN
            if (this.isEn) {
                this.types = this.typesEn
            } else {
                this.types = this.typesZh
            }
            this.getList()
        },
        activated() {
            this.$refs.content.scrollTop = sessionStorage.getItem("news_scroll")
            sessionStorage.setItem("news_scroll", 0)
        },
        mounted() {
            this.barH = this.$refs.tab_top.$refs.bar.offsetHeight;
        },
        methods: {
            handleScroll() {
                this.scrollTop = this.$refs.content.scrollTop
                let bgh = this.$refs.bg_img.offsetHeight
                let al = Number((this.scrollTop / (bgh - this.barH)).toFixed(1))
                this.$refs.tab_top.$refs.bar.style.background = 'rgba(255, 255, 255, ' + al + ')'
                const lis= this.$refs.item
		        //swHeight=滚动的高度+窗体的高度；当li的offset高度<=swHeight,那么说明当前li显示在可视区域了
                const swHeight=document.documentElement.clientHeight
                lis.map((item,index)=>{
                   const mTop=item.getBoundingClientRect().top;
                    if(mTop<=swHeight){
                         setTimeout( ()=> {
                                this.list[index].showAnim = true
                        }, 300)
                    }
                })
            },
            handleMouseEnter(index){
                this.list.map((ele,i)=>{
                    if(i===index){
                        setTimeout( ()=> {
                                this.list[index].showAnim = true
                        }, 300)
                    }
                })
            },
            toDetail(item) {
                if (this.isEn) {
                    this.$router.push({
                        path: this.common.PAGE_EN_NEWS_DETAIL,
                        query: {id: item.id, type: item.type}
                    })
                } else {
                    this.$router.push({
                        path: this.common.PAGE_NEWS_DETAIL,
                        query: {id: item.id, type: item.type},
                    })
                }

            },
            switchInfo(show) {
                this.showTeam = show
            },
            getList() {
                // this.showAnim = false
                this.$request.post('/children/news/listPage',
                    this.qs.stringify({
                        page: 0,
                        rows: 100,
                        language: this.isEn ? 2 : 1
                    }))
                    .then(res => {
                        this.list = res.data.data
                        this.list.map(ele=>{
                            this.$set(ele,'showAnim',false)
                       })
                       if(this.list.length){
                            setTimeout( ()=> {
                                this.list[0].showAnim = true
                        }, 300)
                       }
                    })
            },
            getItemName(item) {
                if (item.type === 1) {
                    return this.types[0]
                } else if (item.type === 2) {
                    return this.types[1]
                } else if (item.type === 3) {
                    return this.types[2]
                }
                return this.types[0]
            },
            getItemClass(index, style) {
                if (index % 4 === 0) {
                    return 'list-item white1 ' + style
                } else if (index % 4 === 1) {
                    return 'list-item black1 ' + style
                } else if (index % 4 === 2) {
                    return 'list-item white2 ' + style
                }
                return 'list-item black2 ' + style
            }
        }
    }
</script>

<style scoped lang="scss" >
    @import "src/common/style/variable";

    .tab-item {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        margin-top: 0;
    }

    .top-title-item {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .top-title {
        font-size: 2rem;
        color: $color-black;
        font-weight: bold;
        cursor: pointer;
        // margin-top: 4rem;
        // line-height: 16rem;
        word-break: break-all;
        opacity: 1;
        transform: translateY(0);

        &:hover {
            color: $color-red;
        }
    }

    .top-img {
        object-fit: cover;
        width: 100%;
        height: 16rem;
        display: block;
    }


    .top-title.red {
        color: $color-red;
    }

    .top-describe {
        margin: 2.5rem 2rem;
        font-size: 1em;
        color: $color-text-title;
        line-height: 1.9em;
        font-weight: 500;
    }

    .list-item {
        position: relative;
        border: .1px solid rgba(0, 0, 0, 0);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
        height: auto;
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        
        &.white1 {
            background-image: url("../assets/news_item_white1.png");
        }

        &.white2 {
            background-image: url("../assets/news_item_white2.png");
        }

        &.black1 {
            background-image: url("../assets/news_item_black1.png");
        }

        &.black2 {
            background-image: url("../assets/news_item_black2.png");
        }

        &:hover {
            opacity: 0.9;
        }
    }
    .item-box{
        transform: translateY(10px);
        opacity: 0;
        transition: all 0s ease 0s;

        &.show {
            transform: translateY(0);
            opacity: 1;
            transition: all .5s ease 0s;
        }

    }
    .list-box {
        transform: translateY(50px);
        opacity: 0;
        transition: all 0s ease 0s;

        &.show {
            transform: translateY(0);
            opacity: 1;
            transition: all .5s ease 0s;
        }
    }

    .list-image {
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
    }

    .list-name {
        font-size: 1em;
        font-weight: bold;
        color: $color-text-title;
        margin: 4rem 2rem;
        text-align: center;

        &.black {
            color: $color-white;
        }
    }

    .list-title {
        font-size: 2.5em;
        font-weight: bold;
        color: $color-text-title;
        margin: 0 2rem;
        text-align: center;

        &.black {
            color: $color-white;
        }
    }

    .list-action {
        margin: 4rem auto;
        width: 6rem;
        font-size: 0.8em;
        font-weight: bold;
        color: $color-text-title;
        padding: 0.5rem 0;
        border: 2px solid $color-black;
        cursor: pointer;
        text-align: center;

        &.black {
            color: $color-white;
            border: 2px solid $color-white;
        }

        &:hover {
            color: $color-red;
            border: 2px solid $color-red;
        }
    }

    @media only screen and (min-width: 768px) {
        .top-title {
            font-size: 3.8rem;
            // line-height: 28vw;
        }
        .top-img {
            height: 28vw;
        }
    }

    @media only screen and (min-width: 1920px) {
        .top-title {
            font-size: 5.6rem;
        }

        .list-title {
            font-size: 3.75rem;
        }
    }


</style>